
import { UserModule } from '@/store/modules/user';
import { Vue, Component } from 'vue-property-decorator';
import { getTfse } from '@/api/tfse';
import { getCommentList, addComment, deleteComment } from '@/api/tfseComment';
import { likeTfse } from '@/api/tfseLike';
import Pagination from '@/components/Pagination/index.vue';

@Component({
	components: {
		Pagination,
	},
})
export default class extends Vue {
	mounted() {
		this.getTfse();
		this.getCommentList();
	}

	private tfse: any = {
		idx: '',
		userUid: '',
		tfseDate: '',
		foodText: '',
		emotionText: '',
		satietyScore: '',
		secretStatus: '',
		likeCount: '',
		createDate: '',
		commentCount: '',
	};

	private commentList: any = {
		uid: '',
		tfseIdx: '',
		userUid: '',
		writer: '',
		contents: '',
		depth: '',
		viewOrder: '',
		hide: '',
		createDate: '',
		children: {},
	}

	private inputComment: any = {
		tfseIdx: this.$route.params.idx,
		contents: '',
		parentUid: '',
	}

	private listQuery = {
		tfseIdx: this.$route.params.idx,
		page: 0,
		size: 10,
	};

	private totalElements = 0;

	private loading = true;

	private completeFlag = false;

	private getTfse() {
		this.loading = true;
		getTfse(this.$route.params.idx).then((res) => {
			this.loading = false;
			this.tfse = res.data;
		});
	}

	private getCommentList() {
		this.loading = true;
		getCommentList(this.listQuery).then((res) => {
			this.commentList = res.data.content;
			this.totalElements = res.data.totalElements;
			this.loading = false;
			this.completeFlag = true;
		});
	}

	private replyHandler(parentUid: String, event: any) {
		if (this.$refs.contents) {
			(this.$refs.contents as HTMLElement).focus();
			event.target.classList.add('none');
			event.target.nextSibling.classList.remove('none');
			if (event.target.nextSibling.nextSibling.classList) {
				event.target.nextSibling.nextSibling.classList.add('none');
			}
			this.inputComment.parentUid = parentUid;
		}
	}

	private replyCancelHandler(event:any) {
		if (this.$refs.contents) {
			event.target.previousSibling.classList.remove('none');
			event.target.classList.add('none');
			if (event.target.nextSibling.classList) {
				event.target.nextSibling.classList.remove('none');
			}
			this.inputComment.parentUid = '';
		}
	}

	private deleteHandler(uid: string) {
		this.$confirm('정말 댓글을 삭제하시겠습니까?').then(() => {
			this.loading = true;
			deleteComment(uid).then(() => {
				this.$message.success('댓글이 삭제되었습니다.');
				this.getTfse();
				this.getCommentList();
				this.inputComment.contents = '';
				this.inputComment.parentUid = '';
				this.loading = false;
				this.resetBtn();
			}).catch((error) => {
				this.$message.error(error.response.data.message || '게시글을 삭제하는데 실패했습니다.');
			});
		});
	}

	private handleLikeTfse(tfse: any) {
		if (UserModule.isLogin) {
			likeTfse(tfse.idx).then(() => {
			tfse.likeStatus = !tfse.likeStatus;
			if (tfse.likeStatus) tfse.likeCount += 1;
			else tfse.likeCount -= 1;
		});
		} else {
			this.$message.info('로그인이 필요한 기능입니다');
			this.$router.push({ name: 'Login' });
		}
	}

	private addComment() {
		if (UserModule.isLogin) {
			this.loading = true;
			addComment(this.inputComment).then(() => {
				this.getTfse();
				this.getCommentList();
				this.inputComment.contents = '';
				this.inputComment.parentUid = '';
				this.loading = false;
				this.resetBtn();
			});
		} else {
			this.$message.info('로그인이 필요한 기능입니다');
			this.$router.push({ name: 'Login' });
		}
	}

	private resetBtn() {
		const cancelElements = document.querySelectorAll('.community__comment-item__button.cancel');
			cancelElements.forEach((element) => {
				element.classList.add('none');
			});
			const replyElements = document.querySelectorAll('.community__comment-item__button.reply');
			replyElements.forEach((element) => {
				element.classList.remove('none');
			});
			const deleteElements = document.querySelectorAll('.community__comment-item__button.delete');
			deleteElements.forEach((element) => {
				element.classList.remove('none');
			});
	}
}
